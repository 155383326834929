import React from "react";
import Page from "./../components/Page";
import { CardRow } from "./../components/Card";
import SEO from "./../components/SEO";
import Section from "../components/Section";

const CardsInfo = [
  {
    title: "Documentation",
    description: "Complete documentation for using and developing for the OHIF",
    link: "https://docs.ohif.org"
  },
  {
    title: "OHIF User Guide",
    description: "A visual tour of how to use and get started using the Viewer",
    link: "https://docs.ohif.org/user-guide/"
  },
  {
    title: "Contributing",
    description: "Learn how to contribute to the OHIF Viewer",
    link: "https://docs.ohif.org/development/our-process/#contributions-pull-requests"
  }
];

function QA({ title, answer }) {
  return (
    <div className="mt-5">
      <h2 className="text-blue-pale text-2xl">{title}</h2>
      <p className="text-white mt-5">{answer}</p>
    </div>
  );
}

function Learn() {
  return (
    <Page>
      <SEO title="Learn | OHIF" />
      <Section className="">
        <h1 className="text-5xl text-blue-100">Learn</h1>
        <p className="text-white mt-5">
          Get started or find detailed answers from this set of curated guides,
          detailed documentation, tutorials, and reference videos.
        </p>
        <CardRow cards={CardsInfo} className="sm:w-1/2" />
        {/* FAQ */}
        <div className="mt-10">
          <h1 className="text-blue-pale text-4xl">
            Frequently Asked Questions
          </h1>
          <>
            <QA
              title="How do I report a bug?"
              answer="Navigate to our GitHub Repository, and submit a new bug report. Follow the steps outlined in the Bug Report Template."
            />

            <QA
              title="How can I request a new feature?"
              answer="At the moment we are in the process of defining our roadmap and will do our best to communicate this to the community. If your requested feature is on the roadmap, then it will most likely be built at some point. If it is not, you are welcome to build it yourself and contribute it. If you have resources and would like to fund the development of a feature, please contact us or work with community members that offer consulting services."
            />

            <QA
              title="Who should I contact about Academic Collaborations?"
              answer="Gordon J. Harris at Massachusetts General Hospital is the primary contact for any academic collaborators. We are always happy to hear about new groups interested in using the OHIF framework, and may be able to provide development support if the proposed collaboration has an impact on cancer research."
            />

            <QA
              title="Does OHIF offer commercial support?"
              answer="The Open Health Imaging Foundation does not offer commercial support, however, some community members do offer consulting services. You can search our Community Forum for more information."
            />

            <QA
              title="Does The OHIF Viewer have 510(k) Clearance from the U.S. F.D.A or CE Marking from the European Commission?"
              answer="NO. The OHIF Viewer is NOT F.D.A. cleared or CE Marked. It is the users' responsibility to ensure compliance with applicable rules and regulations. The License for the OHIF Platform does not prevent your company or group from seeking F.D.A. clearance for a product built using the platform.\n\nIf you have gone this route (or are going there), please let us know because we would be interested to hear about your experience."
            />

            <QA
              title="Is The OHIF Viewer HIPAA Compliant?"
              answer="NO. The OHIF Viewer DOES NOT fulfill all of the criteria to become HIPAA Compliant. It is the users' responsibility to ensure compliance with applicable rules and regulations."
            />
          </>
        </div>
      </Section>
    </Page>
  );
}

export default Learn;
