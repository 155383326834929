import React from "react";
import ButtonLink from "./ButtonLink";

function Card({ title, description, link }) {
  return (
    <div className="card-style">
      <p className="phone-size:subject-link-ps lg:subject-link-lg">{title}</p>
      <p className="text-white my-3">{description}</p>
    </div>
  );
}

function CardRow({ cards, className, Card: CardComponent }) {
  return (
    <div className="flex flex-col space-y-4 mt-5 pl-1 pr-4 sm:flex-row sm:space-x-4 sm:space-y-0">
      {cards.map((card, index) => (
        <ButtonLink
          key={index}
          href={card.link}
          isInternal={!card.link.startsWith("http")}
          className={`w-full ${className}`}
        >
          {CardComponent ? <CardComponent {...card} /> : <Card {...card} />}
        </ButtonLink>
      ))}
    </div>
  );
}

export { Card, CardRow };
